:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #f9f9f9;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  padding: 0;
  margin: 0;
  place-items: center;
  min-width: 320px;
  background-color: #f9f9f9;
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

.btn-generate__api {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
  color: #fff;
  margin-left: auto;
  display: flex;
}
/* .btn-generate__api:hover {
  border-color: #646cff;
}
.btn-generate__api:focus,
.btn-generate__api:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
} */

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

*,
::after,
::before {
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.register-block {
  margin: 60px 0;
  background-color: #fff;
  min-height: 100%;
  width: 100%;
}

.generate__center {
  margin: 80px 0;
}

.index__center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.index__center span {
  color: #d10000;
}

.center {
  margin: 0 auto;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  color: #000;
  box-shadow: 0px 8px 16px 0px #00000040;
  padding: 2.8125em;
  max-width: 424px;
  width: 100%;
}

/* responsive */
@media (max-width: 600px) {
  .center {
    min-width: 424px;
  }
}

@media (max-width: 1920px) {
  .center {
    max-width: 1200px;
  }
}

@media (max-width: 600px) {
  .width-sp-100__width-50-pc {
    min-width: 100%;
    flex: 0 0 auto;
  }
}

@media (max-width: 1920px) {
  .width-sp-100__width-50-pc {
    width: 100%;
  }
}

.divide-input__two {
  display: flex;
  gap: 20px;
}

@media (max-width: 600px) {
  .divide-input__two {
    flex-wrap: wrap;
  }
}

.signup__margin {
  margin: 50px 0;
}

.signup__margin span {
  color: #d10000;
}

.box {
  margin-top: 1rem;
  border: 1px solid #666666;
  padding: 1rem;
  border-radius: 15px;
}

.inline-box-icon {
  display: flex;
  cursor: pointer;
}

.collapse {
  margin-left: auto;
}

p {
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  color: #666666;
}

input {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 1px solid #666666;
  background-clip: padding-box;
  background-color: #fff;
  height: 56px;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 0.5rem;
  color: #000;
  font-family: "Poppins", sans-serif;
}

input:focus {
  background-color: #f2f2f2;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

input[type="submit"] {
  margin-left: -90px;
  height: 50px;
  color: #000;
  border-left: 1px solid #666666;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0px 15px 15px 0px;
  cursor: pointer;
}

/* responsive */
@media (max-width: 1920px) {
  input[type="submit"] {
    width: 15.5%;
  }
}

@media (max-width: 600px) {
  input[type="submit"] {
    width: 24.5%;
  }
}

.btn-sign__up {
  background-color: #2216b7 !important;
  color: #fff;
  width: 100%;
  margin-top: 1rem;
  border-radius: 15px !important;
  height: 56px;
}

.d-flex {
  display: flex;
}

.sign-up {
  margin-top: 0.5rem;
  margin-left: auto;
  color: #2216b7;
}

/* modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  text-align: center;
  width: 300px;
  background-color: white;
  padding: 20px 50px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal-icon {
  background-color: #efbb20;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  left: 114px;
}

.modal-icon span {
  background-color: transparent !important;
  position: absolute;
  top: 5px;
  left: 26px;
  font-size: 3rem;
}

.modal-message {
  background-color: #fff;
  margin-bottom: 2rem;
}

h2 {
  color: #232323;
  background-color: #fff;
}

.btn {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  /* transition: border-color 0.25s; */
}

.btn-default {
  border: none;
  width: 100%;
  height: 42px;
  color: #fff;
}

.btn-default:hover {
  cursor: pointer;
}

/* color indicator */
.color__success {
  background-color: #0e932b !important;
}

.color__warning {
  background-color: #efbb20 !important;
}

.color__danger {
  background-color: #d10000 !important;
}
/* end of color indicator */

.close-btn {
  padding: 0;
  background-color: #fff !important;
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
  color: #222222;
  font-size: 30px;
}

.logout {
  margin: 0 0 0 auto;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
  color: #fff;
  margin-left: auto;
  display: flex;
}

.txt-white {
  color: #fff !important;
}

input.input-error,
select.input-error {
  border-color: #fc8181;
}

.error {
  color: #fc8181;
}

.button:disabled {
  opacity: 0.35;
}
